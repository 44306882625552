import * as React from 'react';
import {Scrollbar} from 'swiper';
import {Grid, IconButton, Paper, Stack, Typography} from '@mui/material';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {useTheme} from '@mui/material/styles';
import CardComponent from './CardComponent';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
// @ts-ignore
import Logo from '../images/logo.svg';
// @ts-ignore
import LogoDark from '../images/logo-dark.svg';
import {ChevronLeft, ChevronRight} from '@mui/icons-material';
import {navigate} from 'gatsby';
import Posts from "./posts";

const hexToRgbA = (hex, transparency) => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${transparency})`;
    }
    throw new Error('Bad Hex');
};

const MainContentZone = ({data, zone, links, style = 'classic'}: { data: any, zone: string, style?: string, links?:any }) => {
    const theme = useTheme();
    const [swiperArray, setSwiperArray] = React.useState<any>([]);
    const [swiperStatesArray, setSwiperStatesArray] = React.useState<any>([]);
    const [onScroll, setOnScroll] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [currentLink, setCurrentLink ] = React.useState(0);
    const paperStyles = () => ({
        background: `linear-gradient(0deg, ${theme.palette.background.paper} 96%, ${onScroll ? hexToRgbA(theme.palette.background.paper, 1) : hexToRgbA(theme.palette.background.paper, 0)} 100%)`,
        height: '100%',
        width: '100%',
        position: 'relative',
        transition: 'background 1s ease-out',
        borderRadius: onScroll ? '10px 10px 0 0' : 0
    });

    React.useEffect(() => {
        window.onscroll = function () {
            let threshold = document.body.clientHeight * 0.1;
            setOnScroll(window.pageYOffset >= threshold);
        };
    });

    const classic = (itemCategory, indexCategory) => (
        <div key={indexCategory}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <Typography sx={{mb: 1, mt: 2}} variant="h6">
                    {itemCategory.title && itemCategory.title}
                </Typography>
                <Stack
                    spacing={2}
                    direction="row"
                    alignItems="center"
                >
                    {
                        (swiperStatesArray[indexCategory] && itemCategory.links?.length > 1 && itemCategory?.showAs !== 'list') && (
                            <>
                                <IconButton
                                    sx={{
                                        borderRadius: 2,
                                        height: 40
                                    }}
                                    disabled={swiperStatesArray[indexCategory].isBeginning}
                                    onClick={() => swiperArray ? swiperArray[indexCategory].slidePrev() : ''}
                                >
                                    <ChevronLeft/>
                                </IconButton>
                                <IconButton
                                    sx={{
                                        borderRadius: 2,
                                        height: 40
                                    }}
                                    disabled={swiperStatesArray[indexCategory].isEnd}
                                    onClick={() => swiperArray ? swiperArray[indexCategory].slideNext() : ''}
                                >
                                    <ChevronRight/>
                                </IconButton>
                            </>
                        )
                    }
                </Stack>
            </Box>
            {
                itemCategory.showAs === 'list' ? (
                    <>
                        {itemCategory.links?.map((itemLink: any, indexLink: number) => (
                            <Box key={indexLink} sx={{position: 'relative', py: 0.5}}>
                                <CardComponent data={itemLink} zone={zone}/>
                            </Box>
                        ))}
                    </>
                ) : (
                    <Swiper
                        modules={[Scrollbar]}
                        slidesPerView="auto"
                        spaceBetween={10}
                        centeredSlides={false}
                        onSlideChange={() => {
                            const prev = [...swiperStatesArray];
                            prev[indexCategory].isBeginning = swiperArray[indexCategory].isBeginning;
                            prev[indexCategory].isEnd = swiperArray[indexCategory].isEnd;
                            setSwiperStatesArray(prev);
                        }}
                        onSwiper={(swiper) => {
                            setSwiperArray((prev: any) => [...prev, swiper]);
                            setSwiperStatesArray((prev: any) => [...prev, {
                                isEnd: false,
                                isBeginning: true
                            }]);
                        }}
                        breakpoints={{
                            "640": {
                                "slidesPerView": 2,
                                "spaceBetween": 10
                            },
                            "768": {
                                "slidesPerView": 2,
                                "spaceBetween": 10
                            },
                            "1024": {
                                "slidesPerView": 3,
                                "spaceBetween": 10
                            }
                        }}
                        freeMode={true}
                    >
                        {itemCategory.links?.map((itemLink: any, indexLink: number) => (
                            <SwiperSlide key={indexLink}>
                                <Box sx={{position: 'relative', py: 0.5}}>
                                    <CardComponent data={itemLink} zone={zone}/>
                                </Box>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )
            }
        </div>
    )

    const newStyle = () => (
        <Grid container>
            { links && links?.map((itemLink: any, indexLink: number) => (
                <Grid item xs={ 4 } key={indexLink}>
                    <CardComponent data={itemLink} zone={zone} onClick={ () => {
                        setCurrentLink( indexLink )
                        setOpen( true )
                    } } fullWidth/>
                </Grid>
            ))}
        </Grid>
    );

    const buttonsStyle = () => (
        <Grid container>
            { links && links?.map((itemLink: any, indexLink: number) => (
                <Grid item xs={ 12 } key={indexLink}>
                    <CardComponent data={itemLink} zone={zone} buttonOnly/>
                </Grid>
            ))}
        </Grid>
    );

    const handleStyle = ( style:string ) => {
        switch (style) {
            case 'new':
                return newStyle();
            case 'buttons':
                return buttonsStyle();
        }
    }
    return (
        <>
            <Posts links={ links } open={ open } setOpen={ setOpen } page={ currentLink }/>
            <Paper
                sx={paperStyles}
            >
                <Container disableGutters={ style === 'new' } sx={{pb: 1, pt: 2}} maxWidth="md">
                    {
                        style === 'classic' ? (
                            data.map((itemCategory: any, indexCategory: number) => (
                                (itemCategory.links?.length && itemCategory.links?.length > 0) &&
                                (
                                    classic( itemCategory, indexCategory )
                                )
                            ))
                        ) : handleStyle(style)
                    }
                    <Box
                        sx={{
                            mt: 4,
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <img
                            onClick={() => navigate('/')}
                            style={{
                                cursor: 'pointer',
                                marginBottom: 8
                            }}
                            width={60}
                            src={theme?.palette?.mode === 'dark' ? Logo : LogoDark}
                            alt="logo-oh-my-zone"
                        />
                    </Box>
                </Container>
            </Paper>
        </>
    );
};

export default MainContentZone;