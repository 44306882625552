import * as React from 'react';
import { fetchRecord } from '../../actions/fetchRecord';
import Banner from '../../components/Banner';
import MainContentZone from '../../components/MainContentZone';
import { fetchRecords } from '../../actions/fetchRecords';
import { ObjectCategoryType } from '../../../../types/ObjectCategoryType';
import { Theme } from '../../components/WithTheme';

import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import { incrementCounter } from '../../actions/incrementCounter';
import Component404 from '../../components/common/Component404';
import Seo from '../../components/seo';
import SplashScreen from '../../components/common/SplashScreen';
const { GATSBY_APP_HOST } = process.env;
// @ts-ignore
import UnderConstruction from '../../images/under_construction.svg';
import { motion, AnimatePresence } from "framer-motion";
import {transition} from "../../constants/transitions";

type LinksItemType = {
    title: string;
    description: string;
    imgUrl: string;
    coverImg: string;
    category: any;
    whatsapp: boolean;
    whatsappMessage?: string;
    buttonText: string;
    isSaved: boolean;
    file?: any;
    zone: string;
}

type CategoryItemType = {
    inputValue?: string;
    title: string;
    links?: Array<LinksItemType> | null;
}

const Zone = ({ zone }) => {
    const [ zoneData, setZoneData ] = React.useState<any>( null );
    const [ categories, setCategories ] = React.useState<CategoryItemType[]>([]);
    const [ links, setLinks ] = React.useState<any>([]);
    const [ previewMode, setPreviewMode ] = React.useState<boolean>( false );
    const [ show404, setShow404 ] = React.useState<boolean>( false );

    React.useEffect(() => {
        setTimeout(() => {
            getZone();
        },2000);
    },[  ]);

    const findCategoryInArray = (category:string, arrayCategories:ObjectCategoryType[]) => {
        let exists = false;

        arrayCategories.map((item:ObjectCategoryType) => (
            (category === item.title) && (exists = true)
        ));

        return exists;
    }

    const getZone = async () => {

        const result = await fetchRecord( `zones/byName`, zone);
        const params = new URLSearchParams(location.search);
        const user  = params.get("user");

        if( !result.zone ) {
            setShow404( true );
            return;
        }

        result.zone.theme.palette.mode = result.zone.theme.mode;

        setZoneData( result.zone );

        //If the url has user param sets preview mode in true, if not increment zone visits
        if( user === result.zone.user._id ) {
            setPreviewMode( true );
        }else {
            //Increment zone visits
            await incrementCounter(`zones/incrementVisits/${ result.zone.uid }`);
        }

        const { links } = await fetchRecords( `links/byZone/${ result.zone.uid }` );

        if( links ) {
            setLinks(links)
            const arrayCategories:any = [];

            links.map((item:any) => {
                if(!findCategoryInArray( item.category.title, arrayCategories )){
                    arrayCategories.push( item.category )
                }

            });

            arrayCategories.map((item:any) => {
                const result = links.filter((obj:any) => {

                    const cat:ObjectCategoryType = obj.category;

                    return cat._id === item._id

                });
                if(result.length > 0) {
                    item.links = result;
                }
            });

            setCategories( arrayCategories );
        }

    }

    return(
        <>
            <AnimatePresence>
                {
                    ( !zoneData && !show404 ) && (
                        <SplashScreen/>
                    )
                }
            {
                show404 && (
                    <Component404/>
                )
            }
            { ( zoneData ) && (
                <Theme palette={ zoneData?.theme?.palette } >
                    <>
                        <Seo
                            title={ zoneData?.title }
                            description={ zoneData?.description }
                            image={ zoneData.profileImage?.url }
                        />
                        {
                            ( zoneData.currentStatus !== 'isPublished' && !previewMode ) && (
                                <Box
                                    sx={{
                                        height: '100vh',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Typography
                                        color="white"
                                        variant="h4"
                                    >
                                        Coming Zone!
                                    </Typography>
                                    <Typography
                                        color="white"
                                        variant="subtitle1"
                                        align="center"
                                    >
                                        El Zone al que intentas acceder aún no está listo...

                                    </Typography>
                                    <img
                                        style={{
                                            marginTop: 20
                                        }}
                                        width={ 350 }
                                        src={ UnderConstruction }
                                    />
                                    <Typography
                                        color="white"
                                        variant="subtitle1"
                                    >
                                        ¿Por qué no creas el tuyo mientras?
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            mt: 2,
                                            textTransform: 'none'
                                        }}
                                        onClick={ () => {
                                            window.open( GATSBY_APP_HOST )
                                        }}
                                    >
                                        ¡Comienza gratis aquí!
                                    </Button>
                                </Box>
                            )
                        }
                        {
                            ( zoneData.currentStatus === 'isPublished' || previewMode ) && (
                                <>
                                    <Banner
                                        data={ zoneData }
                                        backgroundImageUrl={ zoneData?.theme?.backgroundImage?.url }
                                    />
                                    <motion.div
                                        initial={{
                                            marginTop: 680,
                                            opacity: 0
                                        }}
                                        animate={{
                                            marginTop: 380,
                                            opacity: 1,
                                            transition: {
                                                ...transition,
                                                delay: 1.5,
                                                duration: 2
                                            }
                                        }}
                                    >
                                        <MainContentZone
                                            links={ links }
                                            style={ zoneData?.theme?.style }
                                            data={ categories }
                                            zone={ zoneData.uid }
                                        />
                                    </motion.div>

                                </>
                            )
                        }

                    </>
                </Theme>
            ) }
            </AnimatePresence>
        </>
    )
}

export default Zone;