import * as React from 'react';
import {createTheme} from "@mui/material/styles";
import {Fade, ThemeProvider, Typography} from "@mui/material";
import {usePalette} from "react-palette";
import Box from "@mui/material/Box";

const PostInfo = ({ item }:{ item:any }) => {
    const { data, loading } = usePalette(item.coverImg?.url);

    return(
        !loading &&

        <ThemeProvider theme={ createTheme({
            palette: {
                primary: {
                    main: data.vibrant
                }
            }
        }) }>
            <Fade
                in={ !loading }
                timeout={ 1500 }
            >
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        background: `linear-gradient(rgba(0,0,0, 1) 0%, ${ data.vibrant }  40%)`,
                        p: 1,
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <Typography color="white">
                        { item.title }
                    </Typography>
                    <Typography color="white">
                        { item?.category?.title }
                    </Typography>
                </Box>
            </Fade>
        </ThemeProvider>
    )
}

export default PostInfo;