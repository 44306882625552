import * as React from 'react';
import { useTheme, styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Grid, Avatar, Typography, IconButton, Chip } from '@mui/material';
import {Email, Phone, Facebook, Instagram, WhatsApp, YouTube} from '@mui/icons-material';
import { motion } from 'framer-motion';
import { transition, enteringFormTransition, inputTransition } from '../constants/transitions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Tiktok } from 'styled-icons/boxicons-logos';
import { incrementCounter } from '../actions/incrementCounter';

const Banner = ({ data, backgroundImageUrl }:{ data:any, backgroundImageUrl?:string }) => {
    const theme = useTheme();
    const handleClick = (to:string, social:string) => {
        const windowReference = window.open();

        incrementCounter( `zones/incrementVisits/${ data.uid }?buttonName=${ social }` ).then(() => {
            const isWebView = navigator.userAgent.includes ('wv')
            if( isWebView ) {
                window.open( to , '_self');
            }else {
                windowReference.location =  to ;
            }
        }).catch((e) => (
            console.log(e)
        ))
    }

    const StyledBackgroundContainer = styled('div')(() => ({
        backgroundImage: `url(${ backgroundImageUrl })`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center top',
        backgroundSize: 'cover',
        position: 'absolute',
        zIndex: 0,
        height: 500,
        width: '100%',
    }));

    const StyledTraslucidLayer = styled('div')((props:any) => ({
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.3)',
        height: 500,
        width: '100%',
        position: 'absolute',
        zIndex: 1
    }));

    return(
        <Box sx={{
            position: 'fixed',
            zIndex: 0,
            width: '100%',
            height: '100%',
            top: 0,
        }}>
            {
                backgroundImageUrl &&
                <motion.div
                    initial={{
                        opacity: 0
                    }}
                    animate={{
                        opacity: 1,
                        transition: {
                            ...transition,
                            delay: 2.5,
                            duration: 1
                        }
                    }}
                >
                    <StyledTraslucidLayer/>
                    <StyledBackgroundContainer/>
                </motion.div>
            }
            <Container
                sx={{
                    padding: '2rem 0',
                    display:'flex',
                    justifyContent: 'center'
                }}
            >
                <Box
                    sx={{
                        padding: '1.5rem',
                        zIndex: 1,
                    }}
                >
                    <Chip
                        sx={{
                            position: 'absolute',
                            top: 20,
                            right: 20,
                        }}
                        icon={
                            <VisibilityIcon/>
                        }
                        label={ data.visits }
                    />
                    <Grid alignItems="center" direction="column" spacing={ 0 } container>
                        <Grid item xs={12}>
                            {
                                data?.profileImage?.url && (
                                    <motion.div
                                        initial={{
                                            y: 40,
                                            opacity: 0,
                                        }}
                                        animate={{
                                            y: 0,
                                            opacity: 1,
                                            transition: { ...transition, duration: 2 }
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                height: 100,
                                                width: 100,
                                                mb: 0,

                                            }}
                                            alt="Logo brand"
                                            src={ data.profileImage?.url }
                                            variant="circular"
                                            imgProps={{
                                                style: {
                                                    marginBottom: 0
                                                }
                                            }}
                                        />
                                    </motion.div>
                                )
                            }
                        </Grid>
                        {
                            theme?.palette?.text?.primary && (
                                <>
                                    <Grid item xs={12}>
                                        <motion.div
                                            initial={{
                                                y: -5,
                                                opacity: 0,
                                            }}
                                            animate={{
                                                y: 0,
                                                opacity: 1,
                                                transition: { ...transition, duration: 1.5, delay: 2 }
                                            }}
                                        >

                                            <Typography
                                                sx={{
                                                    color: theme.palette.text.primary
                                                }}
                                                align="center" variant="h5">{ data.title ? data.title : 'Título de tu Zone' }</Typography>
                                        </motion.div>
                                    </Grid>
                                    {
                                        data.subtitle &&
                                        <motion.div
                                            initial={{
                                                opacity: 0,
                                            }}
                                            animate={{
                                                opacity: 1,
                                                transition: { ...transition, duration: 1.5, delay: 2 }
                                            }}
                                        >
                                            <Grid item xs={12}>
                                                <Typography sx={{
                                                    color: theme.palette.text.primary
                                                }} align="center" variant="h6">{ data.subtitle }</Typography>
                                            </Grid>
                                        </motion.div>
                                    }
                                    <Typography
                                        sx={{
                                            color: theme.palette.text.primary
                                        }}
                                        variant="body2"
                                        align="center"
                                    >
                                        { data.description }
                                    </Typography>
                                </>
                            )
                        }

                    </Grid>
                    <motion.div initial='initial' animate='animate' exit='exit'>
                        <motion.span variants={enteringFormTransition}>
                            <Grid sx={{ justifyContent: 'center', mt: 2 }} container spacing={2}>
                                { data?.socialLinks?.phone && (
                                    <Grid item>
                                        <motion.div variants={inputTransition}>
                                            <IconButton
                                                sx={{
                                                    backgroundColor: theme.palette.primary.main
                                                }}

                                                aria-label="teléfono"
                                                onClick={ () => handleClick( `tel:+52${ data.socialLinks.phone }`, 'phone' ) }
                                            >
                                                <Phone fontSize="medium" />
                                            </IconButton>
                                        </motion.div>
                                    </Grid>
                                ) }
                                { data?.socialLinks?.email && (
                                    <Grid item>
                                        <motion.div variants={inputTransition}>
                                            <IconButton
                                                sx={{
                                                    backgroundColor: theme.palette.primary.main
                                                }}
                                                aria-label="mail"
                                                onClick={ () => handleClick( `mailto:${ data.socialLinks.email }`, 'email' ) }
                                            >
                                                <Email fontSize="medium" />
                                            </IconButton>
                                        </motion.div>

                                    </Grid>
                                ) }
                                { data?.socialLinks?.facebook && (
                                    <Grid item>
                                        <motion.div variants={inputTransition}>
                                            <IconButton
                                                sx={{
                                                    backgroundColor: theme.palette.primary.main
                                                }}
                                                aria-label="facebook"
                                                onClick={ () => handleClick( `https://facebook.com/${ data?.socialLinks?.facebook }`, 'facebook') }
                                            >
                                                <Facebook fontSize="medium" />
                                            </IconButton>
                                        </motion.div>
                                    </Grid>
                                ) }
                                { data?.socialLinks?.whatsapp && (
                                    <Grid item>
                                        <motion.div variants={inputTransition}>
                                            <IconButton
                                                sx={{
                                                    backgroundColor: theme.palette.primary.main
                                                }}
                                                aria-label="whatsapp"
                                                onClick={ () => handleClick( `https://api.whatsapp.com/send/?phone=${ data?.socialLinks?.whatsapp }&text="Hola+te+contacto+desde+tu+OhMyZone"`, 'whatsapp') }
                                            >
                                                <WhatsApp fontSize="medium" />
                                            </IconButton>
                                        </motion.div>
                                    </Grid>
                                ) }
                                { data?.socialLinks?.instagram && (

                                    <Grid item>
                                        <motion.div variants={inputTransition}>
                                            <IconButton
                                                sx={{
                                                    backgroundColor: theme.palette.primary.main
                                                }}
                                                aria-label="instagram"
                                                onClick={ () => handleClick( `https://instagram.com/${ data?.socialLinks?.instagram }`, 'instagram' ) }
                                            >
                                                <Instagram fontSize="medium" />
                                            </IconButton>
                                        </motion.div>

                                    </Grid>
                                ) }
                                { data?.socialLinks?.tiktok && (

                                    <Grid item>
                                        <motion.div variants={inputTransition}>
                                            <IconButton
                                                sx={{
                                                    backgroundColor: theme.palette.primary.main
                                                }}
                                                aria-label="tiktok"
                                                onClick={ () => handleClick( `https://www.tiktok.com/@${ data?.socialLinks?.tiktok }`, 'tiktok' ) }
                                            >
                                                <Tiktok size={24}/>
                                            </IconButton>
                                        </motion.div>

                                    </Grid>
                                ) }
                                { data?.socialLinks?.youtube && (

                                    <Grid item>
                                        <motion.div variants={inputTransition}>
                                            <IconButton
                                                sx={{
                                                    backgroundColor: theme.palette.primary.main
                                                }}
                                                aria-label="youtube"
                                                onClick={ () => handleClick( `https://www.youtube.com/channel/${ data?.socialLinks?.youtube }`, 'youtube' ) }
                                            >
                                                {/*@ts-ignore*/}
                                                <YouTube size={24}/>
                                            </IconButton>
                                        </motion.div>

                                    </Grid>
                                ) }
                            </Grid>
                        </motion.span>
                    </motion.div>

                </Box>
            </Container>
        </Box>
    )

}

export default Banner;