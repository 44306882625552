import * as React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import Modal from '@mui/material/Modal';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./styles.css";
import { Pagination } from "swiper";
import {Box, Fade, ThemeProvider, Zoom} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Close} from "@mui/icons-material";
import Backdrop from '@mui/material/Backdrop';
import PostInfo from "../common/PostInfo";
const style = {
    position: 'absolute' as 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 'auto',
    width: '100%',
    height: 480,
    bgcolor: 'white',
    boxShadow: 24,
};

const Posts = ({ links, open, setOpen, page }:{ links:any, open?:any, setOpen?:any, page?:any }) => {
    const [swiper, setSwiper] = React.useState<any>();
    const [ currentItem, setCurrentItem ] = React.useState<any>();

    var tapedTwice = false;

    React.useEffect(() => {
        if( !page ) {
            setCurrentItem(  links[0] )
        }else {
            setCurrentItem(  links[page] )
        }

        if( page && swiper ) {
            swiper.slideTo( page, 0 )
        }
        //document.getElementById("double-tap").addEventListener("touchstart", tapHandler);
    },[page, swiper]);
    
    const handleClose = () => {
        setOpen( false )
        setSwiper( null )
    }

    function tapHandler(event) {
        if(!tapedTwice) {
            tapedTwice = true;
            setTimeout( function() { tapedTwice = false; }, 300 );
            return false;
        }
        event.preventDefault();
        //action on double tap goes below
        alert('You tapped me Twice !!!');
    }

    return(
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Zoom in={open}>
                    <Box sx={style}>
                        <Box sx={{ height: 680, position: 'relative' }}>
                            <IconButton
                                onClick={ handleClose }
                                sx={{
                                    position: 'absolute',
                                    top: 16,
                                    right: 16,
                                    zIndex: 200
                                }}
                            >
                                <Close/>
                            </IconButton>
                            <Swiper
                                direction={"vertical"}
                                pagination={{
                                    clickable: true,
                                }}
                                modules={[Pagination]}
                                className="mySwiper"
                                onSwiper={(swiper) => setSwiper(swiper)}
                                onSlideChange={ (something) => setCurrentItem( links[ something.realIndex ] )}
                            >
                                {
                                    links.map( ( item, index:number ) => (
                                        <SwiperSlide key={ index }>
                                            <Box sx={{ height: '100%', backgroundColor:'black' }}>
                                                <img
                                                    style={{
                                                        objectFit: 'contain',
                                                        marginBottom: 0,
                                                        height: '100%'
                                                    }}
                                                    src={ item.coverImg?.url }
                                                    width="100%"
                                                />
                                            </Box>
                                        </SwiperSlide>
                                    ) )
                                }
                            </Swiper>
                            {
                                (currentItem) &&
                                    <PostInfo item={ currentItem }/>
                            }
                        </Box>
                    </Box>
                </Zoom>
            </Modal>



        </>
    )
}

export default Posts;