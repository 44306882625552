import * as React from 'react';
// @ts-ignore
import Logo from '../../images/logo.svg';
import { motion } from 'framer-motion';

export const transition = {
    duration: 0.8,
    ease: [0.6, .01, -0.05, 0.9]
}

const SplashScreen = ( ) => {
    return(
        <motion.div
            style={{
                opacity: 1,
                position: 'fixed',
                height: '100%',
                width: '100%',
                background: '#061E37',
                zIndex: 2000,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden'
            }}
        >
            <motion.div
                initial={{
                    y: 40,
                    opacity: 0,
                }}
                animate={{
                    y: 0,
                    opacity: 1,
                    transition: { ...transition }
                }}
            >
                <motion.img
                    exit={{
                        scale: 4,
                        opacity: 0,
                    }}
                    animate={{
                        opacity: [1, .5, 1],
                        translateY: [ 0, -7, 0 ]
                    }}
                    style={{
                        width: 50
                    }}
                    transition={{ ease: "linear", duration: 1.5, repeat: Infinity }}
                    src={ Logo }
                />
            </motion.div>
        </motion.div>
    )
}

export default SplashScreen;